html {
  font-size: 62.5%;
  box-sizing: border-box;
  --bg-color-green-hover: #8cad8c;
  --bg-color-green: #779a77;
  --bg-color-red-hover: #d05252;
  --bg-color-red: #bb4343;
  --bg-color-light-blue: #f5f5ff;
  --bg-green-1: #e8efe7;
  --bg-hue: 0;
  --bg-sat: 0%;
  --bg-surface-1: hsl(var(--bg-hue), var(--bg-sat), 100%);
  --bg-surface-2: hsl(var(--bg-hue), var(--bg-sat), 98%);
  --bg-surface-3: hsl(var(--bg-hue), var(--bg-sat), 96%);
  --bg-surface-4: hsl(var(--bg-hue), var(--bg-sat), 94%);
  --bg-surface-5: hsl(var(--bg-hue), var(--bg-sat), 92%);
  --bg-surface-6: hsl(var(--bg-hue), var(--bg-sat), 88%);
  --bg-warm-1: #ffffec;
  --bg-warm-2: #f5f5e2;
  --border-dark: solid 1px hsl(0, 0%, 75%);
  --border-light: solid 1px hsl(0, 0%, 87%);
  --border-medium: solid 1px hsl(0, 0%, 80%);
  --border-xlight: solid 1px hsl(0, 0%, 92%);
  --shadow-2: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  --shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
  --text-dark: hsl(0, 0%, 15%);
  --text-light: hsl(0, 0%, 60%);
  --text-medium: hsl(0, 0%, 40%);
}
body {
  margin: 0;
  font-family: proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  height: 100dvh;
}

body.ck-inspector-body-expanded,
body.ck-inspector-body-collapsed {
  height: calc(100vh - var(--ck-inspector-collapsed-height));
}

#root {
  height: 100%;
  font-size: 1.2rem;
}
.ReactModalPortal {
  font-size: 1.2rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  text-underline-offset: 0.25em;
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1 !important;

  position: relative;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
  content: " ";
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  /* background-image: linear-gradient(
      90deg,
      var(--base-color),
      var(--highlight-color),
      var(--base-color)
  ); */
  background-color: #fff;
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
